import logo from '@/assets/images/img_ai.png'
import {
  CheatDetectionIcon,
  DataExtractionIcon,
  DiscordBotIcon,
  GameAnomalyDetectionIcon,
  ImageDetectionIcon,
  MatchMobIcon,
  ProductRecommendationIcon,
  SocialRecommendationIcon,
  TRENDiIcon,
  TextDetectionIcon,
  UserProfileIcon,
  VisionHackDetectionIcon,
} from '@/components/icons'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.gamescaleAI

export const gamescaleAIBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const gamescaleAISEO = i18n.seo

const security: SolutionDetailData = {
  id: 'security',
  name: i18n.security.name,
  title: [i18n.security.title1, i18n.security.title2],
  description: i18n.security.description,
  featureItems: [
    {
      icon: <GameAnomalyDetectionIcon />,
      title: i18n.security.features.gameAnomalyDetection,
      link: i18n.security.features.gameAnomalyDetectionLink,
    },
    {
      icon: <CheatDetectionIcon />,
      title: i18n.security.features.cheatDetection,
      link: i18n.security.features.cheatDetectionLink,
    },
    {
      icon: <VisionHackDetectionIcon />,
      title: i18n.security.features.visionHackDetection,
      link: i18n.security.features.visionHackDetectionLink,
    },
  ],
  featureDetails: [],
}

const moderation: SolutionDetailData = {
  id: 'moderation',
  name: i18n.moderation.name,
  title: [i18n.moderation.title1, i18n.moderation.title2],
  description: i18n.moderation.description,
  featureItems: [
    {
      icon: <DiscordBotIcon />,
      title: i18n.moderation.features.discordBot,
      link: i18n.moderation.features.discordBotLink,
    },
    {
      icon: <TextDetectionIcon />,
      title: i18n.moderation.features.textDetection,
      link: i18n.moderation.features.textDetectionLink,
    },
    {
      icon: <ImageDetectionIcon />,
      title: i18n.moderation.features.imageDetection,
      link: i18n.moderation.features.imageDetectionLink,
    },
  ],
  featureDetails: [],
}

const enhancement: SolutionDetailData = {
  id: 'enhancement',
  name: i18n.enhancement.name,
  title: [i18n.enhancement.title1, i18n.enhancement.title2, i18n.enhancement.title3],
  description: i18n.enhancement.description,
  featureItems: [
    {
      icon: <MatchMobIcon />,
      title: i18n.enhancement.features.matchMob,
      link: i18n.enhancement.features.matchMobLink,
    },
    {
      icon: <ProductRecommendationIcon />,
      title: i18n.enhancement.features.productRecommendation,
      link: i18n.enhancement.features.productRecommendationLink,
    },
    {
      icon: <SocialRecommendationIcon />,
      title: i18n.enhancement.features.socialRecommendation,
      link: i18n.enhancement.features.socialRecommendationLink,
    },
  ],
  featureDetails: [],
}

const analysis: SolutionDetailData = {
  id: 'analysis',
  name: i18n.analysis.name,
  title: [i18n.analysis.title],
  description: i18n.analysis.description,
  featureItems: [
    {
      icon: <TRENDiIcon />,
      title: i18n.analysis.features.trendi,
      link: i18n.analysis.features.trendiLink,
    },
    {
      icon: <DataExtractionIcon />,
      title: i18n.analysis.features.dataExtractionTool,
      link: i18n.analysis.features.dataExtractionToolLink,
    },
    {
      icon: <UserProfileIcon />,
      title: i18n.analysis.features.userprofile,
      link: i18n.analysis.features.userProfileLink,
    },
  ],
  featureDetails: [],
}

export const gamescaleAINav: NavSectionItem[] = [
  {
    id: security.id,
    name: security.name,
  },
  {
    id: moderation.id,
    name: moderation.name,
  },
  {
    id: enhancement.id,
    name: enhancement.name,
  },
  {
    id: analysis.id,
    name: analysis.name,
  },
]

export const gamescaleAISolution: SolutionDetailData[] = [
  security,
  moderation,
  enhancement,
  analysis,
]
