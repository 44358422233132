import logo from '@/assets/images/img_personalized.png'
import {
  CustomPortalIcon,
  DataExtractionIcon,
  GuideRecommendationIcon,
  MatchMobIcon,
  NexonAdvertisementIcon,
  ProductRecommendationIcon,
  SocialRecommendationIcon,
  UserProfileIcon,
} from '@/components/icons'
import '@/components/icons/matchmob'
import { NavSectionItem, SolutionBanner, SolutionDetailData } from '@/configs/solution-detail'
import { i18nLanguage } from '@/i18n'

const i18n = i18nLanguage.solutionDetail.personalizedRecommendation

export const personalizedRecommendationBanner: SolutionBanner = {
  title: i18n.banner.title,
  subtitle: i18n.banner.subtitle,
  description: i18n.banner.description,
  logo: logo,
}

export const personalizeSEO = i18n.seo

const analysis: SolutionDetailData = {
  id: 'analysis',
  name: i18n.analysis.name,
  title: [i18n.analysis.title1, i18n.analysis.title2],
  description: i18n.analysis.description,
  featureItems: [
    {
      icon: <UserProfileIcon />,
      title: i18n.analysis.features.userProfile,
      link: i18n.analysis.features.userProfileLink,
    },
    {
      icon: <DataExtractionIcon />,
      title: i18n.analysis.features.dataExtractionTool,
      link: i18n.analysis.features.dataExtractionToolLink,
    },
  ],
  featureDetails: [],
}

const personalizedContents: SolutionDetailData = {
  id: 'personalizedContents',
  name: i18n.personalizedContents.name,
  title: [
    i18n.personalizedContents.title1,
    i18n.personalizedContents.title2,
    i18n.personalizedContents.title3,
    i18n.personalizedContents.title4,
  ],
  description: i18n.personalizedContents.description,
  featureItems: [
    {
      icon: <GuideRecommendationIcon />,
      title: i18n.personalizedContents.features.guideRecommendation,
      link: i18n.personalizedContents.features.guideRecommendationLink,
    },
    {
      icon: <ProductRecommendationIcon />,
      title: i18n.personalizedContents.features.productRecommendation,
      link: i18n.personalizedContents.features.productRecommendationLink,
    },
    {
      icon: <CustomPortalIcon />,
      title: i18n.personalizedContents.features.customPortal,
      link: i18n.personalizedContents.features.customPortalLink,
    },
  ],
  featureDetails: [],
}

const social: SolutionDetailData = {
  id: 'social',
  name: i18n.social.name,
  title: [i18n.social.title1, i18n.social.title2, i18n.social.title3],
  description: i18n.social.description,
  featureItems: [
    {
      icon: <MatchMobIcon />,
      title: i18n.social.features.matchMob,
      link: i18n.social.features.matchMobLink,
    },
    {
      icon: <SocialRecommendationIcon />,
      title: i18n.social.features.socialRecommendation,
      link: i18n.social.features.socialRecommendationLink,
    },
  ],
  featureDetails: [],
}

const marketing: SolutionDetailData = {
  id: 'marketing',
  name: i18n.marketing.name,
  title: [i18n.marketing.title1, i18n.marketing.title2],
  description: i18n.marketing.description,
  featureItems: [
    {
      icon: <NexonAdvertisementIcon />,
      title: i18n.marketing.features.nexonAdvertisement,
      link: i18n.marketing.features.nexonAdvertisementLink,
    },
  ],
  featureDetails: [],
}

export const personalizedRecommendationNav: NavSectionItem[] = [
  {
    id: analysis.id,
    name: analysis.name,
  },
  {
    id: personalizedContents.id,
    name: personalizedContents.name,
  },
  {
    id: social.id,
    name: social.name,
  },
  {
    id: marketing.id,
    name: marketing.name,
  },
]

export const personalizedRecommendationSolution: SolutionDetailData[] = [
  analysis,
  personalizedContents,
  social,
  marketing,
]
